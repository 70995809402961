import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import agGridUtils from 'common/ui/agGridUtils';

export function nullFormatter(params) {
  if (agGridUtils.isGroupRow(params) || params.value) return params.value;
  return 'N.A.';
}

export function notANumberFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
  return _.isNil(params.value) ? 'NaN' : threeDPFormatter(params);
}

export function twoDPFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (Math.abs(params.value) < 0.0001) return '0';
  return numeral(params.value).format('0.00');
}

export function threeDPFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (Math.abs(params.value) < 0.0001) return '0';
  return numeral(params.value).format('0.0000');
}

export function sixDPFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (Math.abs(params.value) < 0.000001) return '0';
  return numeral(params.value).format('0.000000');
}

export function fxRateFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  return _.round(Number.parseFloat(params.value), 5);
}

export function moneyFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (Math.abs(params.value) < 0.01) return '0';
  if (
    params.colDef &&
    params.colDef.headerName &&
    params.colDef.headerName.includes('Qty') &&
    params.value &&
    params.value !== _.floor(params.value)
  ) {
    return numeral(params.value).format('0,0.0000');
  }
  return numeral(params.value).format('0,0');
}

export function moneyFormatterOrPercentFomatDefaultNull(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (!params.value) return null;
  if (_.isNaN(_.toNumber(params.value))) return params.value;
  const value = params.value;
  // if (params.data.isPercent) {
  //   return `${numeral(value).format('0.0')}%`;
  // }
  // if (params.data.isPpt) {
  //   return `${value}ppt`;
  // }
  const data = params.data;
  const format = value > 100 ? '0,0' : '0.00';
  if (data && data.index) {
    if (data.index.endsWith('%') || ['YoY', 'QoQ'].includes(data.index)) {
      return `${numeral(value).format('0.0')}%`;
    }
    if (data.index.endsWith('(ppt)')) {
      return `${value}ppt`;
    }
    if (['wcRev'].includes(data.index)) {
      return numeral(value).format('0.0%');
    }
    if (['GP Margin', 'NP Margin'].includes(data.index)) {
      return numeral(value).format('0,0.00');
    }
    if (data.index.startsWith('ASP')) {
      return `${numeral(value).format(format)}`;
    }
    if (data.index.endsWith('...')) {
      return `${numeral(value).format(format)}`;
    }
  }
  return numeral(value).format(format);
}

export function percentFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (params.value === undefined || params.value === null) return 'N.A.';
  if (Math.abs(params.value) < 0.0001) return '0%';
  return numeral(params.value).format('0.00%');
}

export function dateTimeFormatter(params) {
  if (params.value === null) {
    return params.value;
  }

  const formatTime = moment(params.value).format('YYYY-MM-DD HH:MM:ss');

  if (formatTime === 'Invalid date') {
    return params.value;
  }

  return formatTime;
}

export function dateFormatter(params) {
  if (params.value === null) {
    return params.value;
  }

  const formatTime = moment(params.value).format('YYYY/MM/DD');

  if (formatTime === 'Invalid date') {
    return params.value;
  }

  return formatTime;
}

export function dateFormatterWithNotGroup(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
  if (params.value === null) {
    return params.value;
  }

  const formatTime = moment(params.value).format('YYYY/MM/DD');

  if (formatTime === 'Invalid date') {
    return params.value;
  }

  return formatTime;
}

export function riskControlLimitFormatter(params) {
  if (params.value === undefined) return null;
  return !!params.value ? 'Above' : 'Under';
}

export function nanFormatter(params) {
  return isNaN(params.value) || params.value === null
    ? params.value
    : numeral(params.value).format('0,0.00');
}

export function booleanFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
  if (_.isNil(params.value)) return 'N.A.';
  return params.value ? 'Y' : 'N';
}

export function sizeBooleanFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
  if (_.isNil(params.value)) return 'N.A.';
  return !_.isEmpty(params.value) ? 'Y' : 'N';
}

export function objectFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
  const stringValue = _.isEmpty(params.value)
    ? 'N.A.'
    : Object.entries(params.value)
        .map(([k, v]) => `[${k}]:${v}`)
        .join(',');

  return stringValue;
}

export function fivePrecisionPercentFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (params.value === undefined || params.value === null) return 'N.A.';
  if (Math.abs(params.value) < 0.000000001) return '0%';
  return numeral(params.value).format('0.00000%');
}

export function fourPrecisionPercentFormatter(params) {
  if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;

  if (params.value === undefined || params.value === null) return 'N.A.';
  if (Math.abs(params.value) < 0.000001) return '0%';
  return numeral(params.value).format('0.0000%');
}
