import {
  threeDPFormatter,
  sixDPFormatter,
  moneyFormatter,
  percentFormatter,
  nullFormatter,
  objectFormatter,
  fivePrecisionPercentFormatter,
  booleanFormatter
} from 'common/utils/valueFormatters';
import { booleanGetter } from 'common/utils/valueGetters';
import {
  sideStyle,
  idleQtyStyle,
  filledPctStyle,
  pendingRouteErrorsStyle
} from 'common/utils/styleChooser';
import EntityMap from 'entities/EntityMap';
import Order from 'features/oms/entities/Order';
import agGridUtils from 'common/ui/agGridUtils';

const abnormalValueGetter = params => {
  if (agGridUtils.isNonAggColInGroupRow(params)) return null;
  return Order.isAbnormal(params.data) ? 'Y' : 'N';
};

export const locateGridColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroupIndex: 0,
    width: 60
  },
  {
    field: 'broker',
    headerName: 'Broker',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroupIndex: 1,
    width: 60
  },
  {
    field: 'loanType',
    headerName: 'LoanType',
    enableRowGroup: true,
    width: 100
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 120
  },
  {
    field: 'qty',
    headerName: 'Qty',
    valueFormatter: moneyFormatter,
    cellClass: 'number',
    width: 70,
    aggFunc: 'sum'
  },
  {
    field: 'externalId',
    headerName: 'Ext Id',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 120
  },
  {
    field: 'filled',
    headerName: 'Filled',
    valueFormatter: moneyFormatter,
    cellClass: 'number',
    width: 70,
    aggFunc: 'sum'
  },
  {
    field: 'borrowRate',
    headerName: 'Rate',
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    width: 80
  },
  {
    field: 'status',
    headerName: 'Status',
    enableRowGroup: true,
    cellClass: 'keyword',
    width: 100
  },
  {
    field: 'error',
    headerName: 'Error',
    enableRowGroup: true,
    cellClass: 'comment',
    width: 100
  }
];

const subOrdersColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter,
    width: 100
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter,
    width: 100
  },
  {
    field: 'qty',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    //cellClassRules: sideStyle(),
    width: 150
  },
  {
    field: 'qtyUsd',
    headerName: 'Value $',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    //cellClassRules: sideStyle(),
    width: 150
  },
  {
    field: 'filled',
    headerName: 'Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    //cellClassRules: sideStyle(),
    width: 100
  },
  {
    field: 'filledPct',
    headerName: 'Filled %',
    cellRenderer: 'percentBarComponent',
    cellClass: 'number',
    width: 100
  },
  {
    field: 'avgPrice',
    headerName: 'Avg Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 100
  }
];

const orderGridColumns = [
  {
    field: 'refId',
    headerName: 'Ref Id',
    cellClass: 'keyword'
  },
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    lockPinned: true
  },
  {
    field: 'mmaTicker',
    headerName: 'MMA Ticker',
    cellClass: 'keyword'
  },
  {
    field: 'sedol',
    headerName: 'Sedol',
    cellClass: 'keyword'
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    cellClass: 'keyword'
  },
  {
    field: 'securityCode',
    headerName: 'Security',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'securityCcy',
    headerName: 'Sec Ccy',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'tradeDate',
    headerName: 'Trade Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'tradeSource',
    headerName: 'Trade Source',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'side',
    headerName: 'Side',
    enableRowGroup: true,
    cellClass: params =>
      ['BUY', 'COVR'].includes(params.value) ? 'long' : 'short'
  },
  {
    field: 'orderType',
    headerName: 'OrderType',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        MKT: '#FF9E28',
        LMT: 'steelblue'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'limitPriceLocal',
    headerName: 'Lmt Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'requestType',
    headerName: 'Req Type',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        TRD: 'steelblue',
        IPO: 'crimson'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'requestStatus',
    headerName: 'Req Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        ACTIVE: 'steelblue',
        FINALIZED: 'lightseagreen',
        ARCHIVE: 'lightslategrey'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'orderStatus',
    headerName: 'Ord Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        ASSIGN: 'coral',
        SENT: 'lightseagreen',
        FILLED: '#FF9E28',
        PARTFILL: 'steelblue',
        REJECTED: 'crimson',
        CANCEL: '#f50',
        WORKING: '#87d068',
        EXPIRED: 'lightslategrey'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'qtyPct',
    headerName: 'AUM %',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'qtyUsd',
    headerName: 'Value $',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'prevFilledQuantity',
    headerName: 'Prev Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'workingQuantity',
    headerName: 'Working Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'idleQty',
    headerName: 'Idle Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: idleQtyStyle()
  },
  {
    field: 'filledQuantity',
    headerName: 'Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'filledPct',
    headerName: 'Filled %',
    cellRenderer: 'percentBarComponent',
    cellClassRules: filledPctStyle()
  },
  {
    field: 'locateDayFilledPct',
    headerName: 'Locate Filled %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      positiveColor: 'steelblue'
    },
    cellClassRules: filledPctStyle()
  },
  {
    field: 'executionAvgPrice',
    headerName: 'Exec Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: sixDPFormatter
  },
  {
    field: 'executionNetPrice',
    headerName: 'Net Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: sixDPFormatter
  },
  {
    field: 'pmCode',
    headerName: 'PM Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'pmStatus',
    headerName: 'PM Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        SUBMIT: '#FF9E28',
        WITHDRAW: 'crimson',
        MODIFY: 'coral'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'complianceStatus',
    headerName: 'Cpl Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        FAIL: 'crimson',
        SKIP: '#f50',
        PASS: '#87d068',
        WARN: '#eb9234'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'complianceError',
    headerName: 'Cpl Error',
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'traderCode',
    headerName: 'Trdr Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    rowGroupIndex: 0
  },
  {
    field: 'traderStatus',
    headerName: 'Trdr Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        REJECT: 'crimson',
        APPROVE: 'lightseagreen'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'bookStatus',
    headerName: 'Book Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        SENT: 'steelblue',
        PASS: 'lightseagreen',
        FAIL: 'crimson'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'traderNote',
    headerName: 'Trdr Note',
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'traderFeedback',
    headerName: 'Trdr Feedback',
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'pmRemark',
    headerName: 'Remark',
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'pmReason',
    headerName: 'Reason',
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'algoCode',
    headerName: 'Algo',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'algoParams',
    headerName: 'Algo Params',
    enableRowGroup: false,
    cellClass: 'comment',
    valueFormatter: objectFormatter
  },
  {
    field: 'isDirectLine',
    headerName: 'Direct Line',
    enableRowGroup: false,
    cellClass: 'keyword',
    valueGetter: booleanGetter
  },
  {
    field: 'hasPendingRouteErrors',
    headerName: 'Route Errors',
    enableRowGroup: false,
    cellClassRules: pendingRouteErrorsStyle(),
    valueGetter: booleanGetter
  },
  {
    // Must specify field since StateSynchronizer will use it to generate colId
    field: 'isAbnormal',
    headerName: 'Abnormal',
    enableRowGroup: false,
    cellClass: 'keyword',
    valueGetter: abnormalValueGetter // The valueGetter take precedence over field
  },
  {
    field: 'locatesCount',
    headerName: 'Locates',
    valueFormatter: nullFormatter,
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: 'Locates',
      tooltipField: 'locates',
      tooltipColumnDefs: locateGridColumns,
      tooltipWidth: '870px',
      tooltipHeight: '390px'
    },
    cellClass: 'keyword'
  },
  {
    field: 'timeInForce',
    headerName: 'TIF',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        DAY: 'steelblue',
        GTC: '#87d068'
      }
    }
  },
  {
    field: 'country',
    headerName: 'Country',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'isNoCashShrt',
    headerName: 'No Cash SS',
    cellClass: 'keyword',
    valueGetter: booleanGetter
  },
  {
    field: 'isAutoFinalize',
    headerName: 'Auto Finalize',
    cellClass: 'keyword',
    valueGetter: booleanGetter
  },
  {
    field: 'autoRuleRefId',
    headerName: 'Auto Rule',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'avgVolume',
    headerName: 'Avg Volume',
    cellClass: 'comment',
    valueFormatter: fivePrecisionPercentFormatter
  },
  {
    field: 'requestTime',
    headerName: 'Req Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'lastModifiedTime',
    headerName: 'Last MOD Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'finalizeDateTime',
    headerName: 'Finalize Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'archiveDateTime',
    headerName: 'Archive Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'basket',
    headerName: 'Basket',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'txnClass',
    headerName: 'Txn Class',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'settleDate',
    headerName: 'Settle Date',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'maturityDate',
    headerName: 'Maturity Date',
    enableRowGroup: true,
    cellClass: 'non-number',
    valueFormatter: nullFormatter
  },
  {
    field: 'subOrdersCount',
    headerName: 'Sub Orders',
    valueFormatter: nullFormatter,
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: 'Sub Orders',
      tooltipField: 'subOrders',
      tooltipColumnDefs: subOrdersColumns,
      tooltipWidth: '850px',
      tooltipHeight: '390px'
    },
    cellClass: 'keyword'
  },
  {
    field: 'instClass',
    headerName: 'Inst Class',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'slimSec',
    headerName: 'Slim Sec',
    cellClass: 'non-number',
    cellRenderer: 'jsonTooltipComponent'
  },
  {
    field: 'lotSize',
    headerName: 'Lot Size',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'productId',
    headerName: 'Product',
    cellClass: 'non-number',
    cellRenderer: 'clickLabelCompent',
    cellRendererParams: {
      tooltipTitle: 'Product',
      tooltipField: 'productId',
      tooltipWidth: '520px',
      tooltipHeight: '260px'
    }
  },
  {
    field: 'pnlBookFxRate',
    headerName: 'Pnl Book FxRate',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'bestLocate',
    headerName: 'Best Locate',
    cellClass: 'non-number'
  },
  {
    field: 'advisorCode',
    headerName: 'Advisor Name',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    hide: true
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    enableRowGroup: true,
    valueFormatter: nullFormatter,
    hide: true
  }
];

const algoExecStatesColumns = [
  {
    field: 'code',
    headerName: 'Code',
    cellClass: 'text',
    width: 250
  },
  {
    field: 'value',
    headerName: 'Value',
    cellClass: 'text',
    width: 150
  }
];

export const algoExecGridColumns = [
  {
    field: 'key',
    headerName: 'Key',
    cellClass: 'text',
    width: 100
  },
  {
    field: 'algoExecCxlStatus',
    headerName: 'Status',
    cellClass: 'text',
    width: 150,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        NORMAL: '#87d068',
        WARNING: '#eb9234',
        ERROR: 'crimson'
      }
    }
  },
  {
    field: 'algoExecCxlStatesCount',
    headerName: 'Detail',
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: 'Algo Exec CxlStates',
      tooltipField: 'algoExecCxlStates',
      tooltipColumnDefs: algoExecStatesColumns,
      tooltipWidth: '420px',
      tooltipHeight: '300px'
    },
    cellClass: 'keyword',
    width: 150
  }
];

const slimOrderGridColumns = [
  {
    field: 'refId',
    headerName: 'RefId',
    cellClass: 'keyword',
    width: 100
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter,
    width: 50
  },
  {
    field: 'side',
    headerName: 'Side',
    enableRowGroup: true,
    cellClass: params =>
      ['BUY', 'COVR'].includes(params.value) ? 'long' : 'short',
    width: 70
  },

  {
    field: 'qty',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle(),
    width: 80
  },
  {
    field: 'algoCode',
    headerName: 'Algo',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter,
    width: 80
  },
  {
    field: 'algoParams',
    headerName: 'Algo Params',
    enableRowGroup: false,
    cellClass: 'comment',
    valueFormatter: objectFormatter,
    width: 160
  },

  {
    field: 'filledPct',
    headerName: 'Filled %',
    cellRenderer: 'percentBarComponent',
    cellClassRules: filledPctStyle(),
    width: 50
  },

  {
    field: 'limitPx',
    headerName: 'Lmt Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 80
  },

  {
    field: 'reqTime',
    headerName: 'Req Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 150
  },
  {
    field: 'filledTime',
    headerName: 'Filled Time',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 150
  }
];

export const crossInfoGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    width: 120
  },
  {
    field: 'fundCode',
    headerName: 'Fund',
    cellClass: 'keyword',
    width: 120
  },
  {
    field: 'crossCount',
    headerName: 'Count',
    cellClass: 'non-number',
    width: 80
  },
  {
    field: 'crossQty',
    headerName: 'Qty',
    cellClass: 'non-number',
    width: 130,
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: 'Orders',
      tooltipField: 'orders',
      tooltipColumnDefs: slimOrderGridColumns,
      tooltipWidth: '1000px',
      tooltipHeight: '390px'
    }
  },
  {
    field: 'isPxCross',
    headerName: 'Price-X',
    cellClass: 'non-number',
    valueFormatter: booleanFormatter,
    width: 80
  },
  {
    field: 'crossWorkingCount',
    headerName: 'W-Count',
    cellClass: 'non-number',
    width: 80
  },
  {
    field: 'crossWorkingQty',
    headerName: 'W-Qty',
    cellClass: 'non-number',
    width: 130
  },
  {
    field: 'isWorkingPxCross',
    headerName: 'W-Price-X',
    cellClass: 'non-number',
    valueFormatter: booleanFormatter,
    width: 80
  }
];

export const orderGridColumnMap = EntityMap.fromArray(
  orderGridColumns,
  'field'
);
